import { Button, Cascader, Checkbox, Col, Form, Input, Modal, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { AddressModalStore } from './store';
import { toJS } from 'mobx';

const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const titleMap = {
  add: '新增收货地址',
  edit: '编辑收货地址',
};

export const AddressModal: React.FC<{ store: AddressModalStore; }> = observer((props) => {
  const {
    visible,
    loading,
    setRef,
    loadData,
    addressOptions,
    onParserAddressChange,
    address,
    parsingAddress,
    type,
    onCancel,
    onOk,
    confirmLoading,
  } = props.store;
  return (
    <Modal
      className={styles.modal}
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={onCancel}
      onOk={onOk}
      open={visible}
      title={titleMap[type]}
      width={577}
    >
      <Spin spinning={loading}>
        <Form
          ref={setRef}
          {...formLayout}
        >
          <Form.Item
            label="收货人"
            name="receiverName"
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: '请输入收货人',
              },
            ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="手机号"
            name="receiverMobile"
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: '请输入手机号',
              },
              {
                pattern: /^1\d{10}/,
                message: '手机号格式不正确',
              },
            ]}
          >
            <Input maxLength={11}/>
          </Form.Item>
          <Form.Item
            label="所在地区"
            name="address"
            required
            rules={[
              {
                required: true,
                message: '请选择所在地区',
              },
            ]}
          >
            <Cascader
              loadData={loadData}
              options={toJS(addressOptions)}
            />
          </Form.Item>
          <Form.Item
            label="详细地址"
            name="receiverAddress"
            required
            rules={[
              {
                required: true,
                whitespace: true,
                message: '请输入详细地址',
              },
            ]}
          >
            <Input.TextArea style={{
              height: '56px',
              resize: 'none',
            }}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="defaultAddress"
            style={{
              paddingLeft: '90px',
              height: '18px',
            }}
            valuePropName="checked"
          >
            <Checkbox>
              设为默认地址，方便下次使用
            </Checkbox>
          </Form.Item>
        </Form>
        <Row>
          <Col span={4}>
            <header className={styles.parsingHeader}>
              智能解析：
            </header>
          </Col>
          <Col span={20}>
            <div>
              <Input.TextArea
                onChange={onParserAddressChange}
                onPressEnter={onParserAddressChange}
                placeholder="粘贴或输入文本可快速识别地址信息，如：张三13800138000 江苏省苏州常熟市莫干路1号云裳大厦A栋21楼衫数科技"
                style={{
                  height: '89px',
                  resize: 'none',
                }}
                value={address}
              />
            </div>
          </Col>
        </Row>

        <div className={styles.parsingBtn}>
          <Button
            ghost
            onClick={parsingAddress}
            type="primary"
          >
            一键解析
          </Button>
        </div>

      </Spin>
    </Modal>
  );
});
